<template>
  <div>
    <a-form :form="form"
            @submit="handleSubmit"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }">
      <a-row>
        <a-col :lg="12"
               :md="12"
               :sm="24">
          <a-form-item label="当前项目"
                       class="default-highlight"
                       :label-col="{ span: 8 }"
                       :wrapper-col="{ span: 12 }">
            <a-input :disabled="true"
                     :placeholder="designCode"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="24"
               :md="24"
               :sm="24">
          <a-form-item label="工程名称"
                       class="default-highlight">
            <a-input :disabled="true"
                     :placeholder="projectName"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="24"
               :md="24"
               :sm="24">
          <div class="file-title">
            <div class="line"></div>
            <div class="title">文件列表</div>
          </div>
        </a-col>
        <a-col :lg="20"
               :md="20"
               :sm="20"
               :offset="2">
          <div v-for="(value, key) in fileObj"
               :key="key"
               class="file-container">
            <FileItem @addFile="getFile"
                      :name="key"
                      :msg="selectFile[key]" />
          </div>
        </a-col>
      </a-row>
      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
          <a-button @click="saveFile"
                    class="save">暂存</a-button>
          <a-button html-type="submit"
                    type="primary">提交</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import FileItem from "./FileItem";

import { fetchDetail } from "@/api/epc";
import {
  add,
  save,
  edit,
  fetchSaveData,
  fetchDetail as fetchFileDetail,
} from "@/api/epc/construction";

import { groupBy, recoveryArr } from "@/utils/groupBy";

export default {
  components: {
    FileItem,
  },
  data() {
    return {
      stage: "epc_construction_security_documents",
      isView: false,

      form: this.$form.createForm(this),
      pid: "",
      id: "",
      hid: "",
      projectName: "",
      designCode: "",

      fileObj: {
        项目综合应急预案: [],
        各种安全培训记录: [],
        特殊工种资格证和安全管理人员资格证台账: [],
        安全隐患整改通知单: [],
        安全隐患整改回复单: [],
        "各种机械设备安全检查记录、安全检验合格证台账": [],
        安全技术交底: [],
        现场巡检记录: [],
        重大危险源辨识清单: [],
        安全会议记录和报告: [],
        各种作业许可证: [],
        安全日志: [],
        生产安全事故快报表: [],
        总承包项目部各种安全通知: [],
        安全记录检查记录表: [],
        安全管理协议: [],
        相关方台账: [],
        安全投入台账: [],
      },
      selectFile: {}, //选中的文件
    };
  },

  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    // 默认展示暂存的内容
    this.getSaveData();

    if (this.id) {
      this.getDetail();
    }
    this.getName();
  },
  methods: {
    getSaveData() {
      fetchSaveData().then((res) => {
        if (!res) {
          return;
        }
        // 若有暂存内容，则展示暂存，否则新建项目
        if (res.attachments) {
          this.selectFile = groupBy(res.attachments, "type");
        }
      });
    },

    getDetail() {
      fetchFileDetail({ id: this.id }).then((res) => {
        if (!res.id) return;

        if (res.attachmentList) {
          this.selectFile = groupBy(res.attachmentList, "type");
        }
      });
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    getFile(val) {
      // 根据name，替换选择的文件
      this.selectFile[val.name] = val.fileList;
      console.log(this.selectFile);
    },

    saveFile() {
      // 对每个文件添加type
      for (let key in this.selectFile) {
        this.selectFile[key].forEach((item) => {
          item.type = key;
        });
      }
      const paramList = recoveryArr(this.selectFile);
      console.log(paramList);

      save({
        pid: this.pid,
        projectName: this.projectName,
        designCode: this.designCode,
        attachments: paramList,
        type: this.stage,
      });
    },

    handleSubmit(e) {
      e.preventDefault();

      // 对每个文件添加type
      for (let key in this.selectFile) {
        this.selectFile[key].forEach((item) => {
          item.type = key;
        });
      }

      const paramList = recoveryArr(this.selectFile);
      console.log(paramList);

      this.form.validateFields((err) => {
        if (!err) {
          // 如果没有id ，则新增

          if (!this.id) {
            add({
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: paramList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          } else {
            edit({
              id: this.id,
              historyId: this.hid,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: paramList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.file-title {
  width: 15%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  .line {
    width: 5px;
    margin-right: 10px;
    background-color: #1890ff;
  }
  .title {
    text-align: end;
  }
}
.file-container {
  margin-bottom: 10px;
}
.center {
  margin-top: 80px;
}
</style>

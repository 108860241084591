<template>
  <div>
    <Pane :bottom="0"
          :showRemarks="false">
      <a-tabs v-model="activeKey"
              :tabBarStyle="{
          margin: '0',
        }">
        <a-tab-pane v-for="item in tabList"
                    :key="item.key"
                    :tab="item.name"
                    :disabled="item.disabled"></a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload :isView="isView"
                        :url="imgUrl"
                        :process-disable="processDisable"
                        @exportForm="exportForm"
                        @download="download" />
      </template>
    </Pane>

    <div class="container">
      <Record ref="recordRef"
              v-show="activeKey === '0'" />
      <FileList v-show="activeKey === '1'" />
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import Record from "./components/Record";
import FileList from "./components/FileList";

import { mapGetters } from "vuex";

export default {
  name: "construSaf",
  components: {
    ExportDownload,
    Record,
    FileList,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/4-4anquan.png"),
      processDisable: false,

      activeKey: "0",
      form: this.$form.createForm(this),
      pid: "", // 项目id
      projectName: "",

      fileList: [],
      selectedFileList: [],

      isView: false,
    };
  },
  watch: {
    activeKey: {
      handler(newV) {
        if (newV === "0") {
          this.processDisable = false;
        } else {
          this.processDisable = true;
        }
      },
    },
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    tabList() {
      let list = [
        { key: "0", disabled: false, value: "epc_construction_security" },
        {
          key: "1",
          disabled: false,
          value: "epc_construction_security_documents",
        },
      ];
      list.forEach((item) => {
        item.name = this.findSingleDictName("epc_stage", item.value);
      });
      return list;
    },
  },
  mounted() {
    const { query } = this.$route;
    const { activeKey, pid, id, isView } = query || {};

    if (!pid) {
      return;
    }
    this.pid = pid;
    this.activeKey = activeKey || "0";
    this.id = id || "";
    this.isView = Boolean(isView) || false;

    if (id) {
      this.setTab();
    }
  },
  methods: {
    setTab() {
      this.tabList.forEach((item) => {
        if (item.key !== this.activeKey) {
          item.disabled = true;
        }
      });
    },
    download() {
      this.$refs.recordRef.download();
    },
    exportForm() {
      this.$refs.recordRef.exportForm();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
